
// Fonts
@import url(https://fonts.googleapis.com/css?family=Raleway:300,400,600);

// Variables
@import "variables";

.row-head div {
  border-bottom: 1px solid black;
}
.return-entry {
  padding: 10px 0;
}
.return-entry:nth-child(2n) {
  background-color: #fff;
}
.return-entry:nth-child(2n+1) {
  background-color: #eee;
}

#toastMessage {
  display: none;
  width: 310px;
  height: 150px !important;
  position: fixed;
  left: 50%;
  top: 50%;
  background-color: #D70F0F;
  color: #F0F0F0;
  font-family: Calibri;
  font-size: 22px;
  padding: 12px;
  text-align: center;
  border-radius: 8px;
  box-shadow: 0px 0px 24px -1px #383838;
  margin-left: -160px;
  margin-top: -75px;
  z-index: 999;

  .closeBtn {
    position: absolute;
    color: #000000;
    bottom: 0;
    right: 5px;
  }
}

.line_item {
  position: relative;
  height: 100px;
  margin: 5px 0;
  cursor: pointer;
  color: #000;
  background-color: #fff;
  img {
    margin-right: 10px;
    height: 100px;
    float: left;
  }
  .returning_quantity {
    position:absolute;
    bottom: 5px;
    right: 5px;
    display: none;
    a {
      margin: 0 10px;
      font-size: 16px;
      color: #aaa;
    }
    .picked {
      color: #fff;
    }
  }
}
.line_item.active {
  background-color: #20895e;
  color: #fff;
}

ul {
  list-style-type: none;
}
.circle {
  display: block;
  height: 20px;
  width: 20px;
  border-radius: 10px;
  border: 1px solid #000;
  background-color:#fff;
  text-align: center;
}
.active0 {
  background-color:#ff0000;
}
.active1 {
  background-color:#00ff00;
}


#loading {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: none;
}

.pin-popup-user {
  width: 450px;
  height: 260px;
  position: absolute;
  top: 100px;
  margin: 0 auto;
  background-color: #bababa;
  padding: 100px;
  border: solid #999;
  border-radius: 15px;
}
.x-close {
  position: absolute;
  right: 11px;
  top: 5px;
  font-size: 20px;
  font-weight: 600;
  color: #c75656;
  cursor: pointer;
}
.error-pin-user {
  color: red;
  font-size: 16px;
  margin-top: 15px;
}

/* Transparent Overlay */
#loading:before {
  content: '';
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.3);
}

/* :not(:required) hides these rules from IE9 and below */
#loading:not(:required) {
  /* hide "loading..." text */
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

#loading:not(:required):after {
  content: '';
  display: block;
  font-size: 10px;
  width: 1em;
  height: 1em;
  margin-top: -0.5em;
  -webkit-animation: spinner 1500ms infinite linear;
  -moz-animation: spinner 1500ms infinite linear;
  -ms-animation: spinner 1500ms infinite linear;
  -o-animation: spinner 1500ms infinite linear;
  animation: spinner 1500ms infinite linear;
  border-radius: 0.5em;
  -webkit-box-shadow: rgba(0, 0, 0, 0.75) 1.5em 0 0 0, rgba(0, 0, 0, 0.75) 1.1em 1.1em 0 0, rgba(0, 0, 0, 0.75) 0 1.5em 0 0, rgba(0, 0, 0, 0.75) -1.1em 1.1em 0 0, rgba(0, 0, 0, 0.5) -1.5em 0 0 0, rgba(0, 0, 0, 0.5) -1.1em -1.1em 0 0, rgba(0, 0, 0, 0.75) 0 -1.5em 0 0, rgba(0, 0, 0, 0.75) 1.1em -1.1em 0 0;
  box-shadow: rgba(0, 0, 0, 0.75) 1.5em 0 0 0, rgba(0, 0, 0, 0.75) 1.1em 1.1em 0 0, rgba(0, 0, 0, 0.75) 0 1.5em 0 0, rgba(0, 0, 0, 0.75) -1.1em 1.1em 0 0, rgba(0, 0, 0, 0.75) -1.5em 0 0 0, rgba(0, 0, 0, 0.75) -1.1em -1.1em 0 0, rgba(0, 0, 0, 0.75) 0 -1.5em 0 0, rgba(0, 0, 0, 0.75) 1.1em -1.1em 0 0;
}

/* Animation */

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-moz-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-o-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}


.popup-wrap {
  position: fixed;
  top:0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(211, 211, 211, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
}

.popup {
  max-width: 50%;
  background-color: #bababa;
  padding: 100px;
  border: solid #999;
  border-radius: 15px;
  position:relative;
}
.x-close {
  position: absolute;
  right: 11px;
  top: 5px;
  font-size: 20px;
  font-weight: 600;
  color: #c75656;
  cursor: pointer;
}