.selected-div {
    background-color: #bddee9 !important;
}
label {
    font-size: unset;
    font-weight: unset;
}
.input-holder {
    position: absolute;
    top: 45px;
    left: 0px;
    z-index: 100;
    font-size: 10px;
    overflow-wrap: break-word;
}